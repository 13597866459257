.dashboard-header {
    border-bottom: 1px solid $dark;
    min-height: 50px;
    padding: 10px 0 10px 0;
    background: $dark;
    color: $light;
}
.background-dashboard{
    // background: linear-gradient(35deg,$primary-color, $violet);
}
.dashboard-core {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between; 

    .left-content {
        max-width: 250px;
        width: 250px;
    }

    .main-content {
        padding: 25px;
        width: 100%;
    }
}

.dashboard-core-leftmenu {
    background: $dark;
    padding: 25px;
    // margin-top: 25px;
    border-radius: 8px;
    // border: 1px solid $border-color-dashboard;
    min-width: 225px;
    width: 225px;
    max-width: 225px;
    position: fixed;
    top:0; 
    height: 100%; 
    left: 0;
    border-radius: 0;
    z-index: 1;
    .dashboard-name{
        color: $primary-color;
        min-height: 50px; 
        display: flex; 
        align-items: center; 
        justify-content: flex-start;
        margin-bottom: 15px;
    }
    li {
        list-style-type: none;
    }

    li a {

        // min-height: 35px;
        // display: flex;
        // transition: all 0.3s; 
        // justify-content: flex-start;
        // align-items: center;
        // padding: 10px;
        // color: #444;
        // &:hover{
        //     background: #f8f8f8;
        // }
        min-width: 100%;
        color: $light!important;
        button {
            text-align: left !important;
            color: $light;
            font-size: 14px;
            text-transform: capitalize;
            min-width: 100% !important;
            width: 100% !important;
            justify-content: flex-start !important;
            &:hover{
                background: $primary-color;
            }
        }
    } 
    svg{
        transition: ease .3s;
        fill: $light;
    }
    svg:hover{
        transform: translateY(-5px);
    }
}

.dashboard-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-item-wrap {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-content: flex-start;
    margin-top: 25px;
    // gap: 10px;


.dashboard-item {
    background: rgba(255,255,255,0.4);
    color: $light;
    // border: 1px solid $border-color-dashboard;
    padding: 20px;
    border-radius: 8px;
    min-height: 150px;
    min-width: 200px; 
    width: 95%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: space-between;
    h4{
        border-bottom: 2px solid $light;
        padding-bottom: 10px;
    }
    .app-total{
        color: $light;
    }
}
}

.copyright-rising-wrap{
    color: $light;
    a{
        color: $light;
    }
}