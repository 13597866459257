a {
	//color: $light;
	text-decoration: none;
}

%light-article {
	box-shadow: 0 3px 18px #0000000d;
	border: 2px solid #f8f8f8;
}

.last-books-section {
	min-height: 450px;
	//background: $light;
	padding-bottom: 50px;

	.last-books-section-container {
		width: 80%;
		margin: auto;

		@media (max-width: $tablet) {
			width: 100%;
		}
	}

	h2 {
		text-align: center;
		text-transform: uppercase;
	}

	.last-books-wrap {
		//display: flex;
		//flex-direction: column;
		// background: #f7f7f7; 
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

		@media (max-width: 900px) {
			display: flex;
			flex-direction: column;
		}


	}

	.last-books-cover {
		background: $violet;
		width: 200px;
		min-height: 100px;
	}

	.see-books {
		text-align: center;
	}
}

.about-me {
	/*font-size: 40px;
	font-weight: bold;*/
}

.shop-wrap {
	background: $blue-banner;
}

.shop-article-wrap {
	display: grid;

}

.contact-section {
	min-height: 600px;
	//background: #f5f5f2;
	// background: $light;
	// background: $app-logo-color;
	color: $light;

	h2 {
		color: $light;
	}

	.contact-container {
		text-align: center;
		padding: 25px;

		@media (max-width: $tablet) {
			padding: 5px;
		}
	}

	.contact-form-wrap {
		// background: $light;
		min-height: 400px;
		margin: 0 auto;
		padding: 25px;

		@media (max-width: $tablet) {
			padding: 10px;
		}

		.app-bt {
			margin: auto;
		}
	}

	.app-bt {
		color: $light;
		border-color: $light;

		svg {
			fill: $light;
		}
	}

	.contact-wrap {
		label {
			display: none;
		}

		input,
		textarea {
			margin-bottom: 25px;
			min-height: 35px;
			width: 50%;
			min-width: 250ox;
			padding: 5px;
			//border-radius: 5px;
			border: none;
			border-bottom: 2px solid $light;
			color: $light;

			@media (max-width: $tablet) {
				width: 100%;
			}
		}

		input::placeholder {
			color: $light;
		}

		textarea {

			min-height: 200px;
		}
	}
}

.portfolio-section {
	min-height: 450px;
	// background: $dark;
	color: $light;

	.portfolio-wrap {
		display: flex;
		justify-content: space-between;
	}


}

.services-section {
	min-height: 600px; 
	width: 50%;
	margin: 0 auto;
	min-width: 250px; 
	@media (max-width: 1000px)
	{
		width: 70%;
	}
	@media (max-width:  500px)
	{
		width: 100%;
	}
	.services-wrap {
		display: grid;

		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		//grid-template-columns: 50% 50%;
		justify-content: center;
		width: 80%;
		margin: 0 auto;

		@media (max-width: $tablet) {
			display: grid;
			flex-direction: column;
			width: 100%;
		}
	}

	h2 {
		text-align: center;
		color: $light;
	}


}

.shop-section {
	min-height: 600px;
	// background: $light;


	// .shop-article-wrap {
	// 	display: grid;
	// 	justify-content: space-between;
	// 	grid-template-columns: 50% 50%;

	// 	@media (max-width:$tablet) {
	// 		flex-direction: column;
	// 		width: 100%;

	// 	}


	// }
}

.blog-section {
	min-height: 450px;
	// padding: 0 0 20% 20%;
}