#employee-profile-menu {
    background: $blue-employee;

    li a {

        // color: $dark;
        color: $light;
        &:hover {
            // color: $light;
            border-bottom-color: $light;
        }
    }
}