.elem-row{
    display: flex;
}
.elem-row-flex{
    display: flex; 
    justify-content: space-between;
    align-items:center;
}
.elem-col{
    padding: 10px;
}
 
.elem-center, .elem-text-center{
    display: flex; 
    justify-content: center;
    align-items: center;
    a{
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
    }
}
.elem-login-bar{
    border-bottom: 2px solid $light-border-color;
    color: $primary-color;
    padding-bottom: 10px;
    margin-bottom: 10px;
    a{
        color: $primary-color;
    }
}
.elem-label-title{
    transition: all 0.3s; 
    display: block; 
    margin-bottom: -2px;
    &:hover{
        transform: translateY(-2px);
    }
}

.elem-row{
    width:100%; 
    padding-left: 10%; 
    padding-right: 10%;
    display: flex; 
    justify-content: space-between;
}

.elem-flex-center{
    display: flex; 
    align-items: center;
    justify-content: center;
    
    // flex-direction: column;
}

.elem-light-title{
    background: $light; 
    border: 1px solid $border-color-dashboard;
    min-height: 35px; 
    padding: 15px 15px 15px 15px;
    border-radius: 5px;
    margin:0 0 10px 0;
    transition: ease .3s;
    &:hover{
        background-color: $light-border-color;
    }
}

.elem-light-card{
    background: $light; 
    border: 1px solid $border-color-dashboard;
    min-height: 35px; 
    padding: 15px 15px 15px 15px;
    border-radius: 5px;
    margin:0 0 10px 0;
    min-height: 150px;
    width: 25%; 
    min-width: 200px;
    transition: ease .3s;
}
.elem-light-card-wrapper{
    display: flex; 
    justify-content: flex-start;
}