input,select,textarea,button{
	border: 1px solid $border-color;
    background: $light;
    min-height: 35px;
    padding: 5px;
}

.form-text-editor-footer{
    background: #f8f8f8;
    display: flex;
    justify-content: flex-end;
    min-width: 100%; 
    position: absolute;
    bottom: 0; 
    left: 0;
    border-top: 1px solid $border-color;
    padding: 10px 5px 10px 5px;
 }
