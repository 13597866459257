.app-core-logged-in{
	// background: fixed no-repeat, $body-main-background; 
}
.app-profile-page-container {
	// padding-left: 10%;
	// padding-right: 10%;
	max-width: 900px;
	width: 100%;

	@media (max-width: $large) {
		// padding-left: 25px;
		// padding-right: 25px;
		padding: 0;
	}

	.profile-main-content {
		padding: 25px;
	}

	.modal-box {
		h2 {
			text-transform: uppercase;
		}

		button {
			@extend .app-bt;
			margin: 0;
		}
	}

	.app-profile-wrap {
		min-height: 600px;
	}

	.user-main-profile {
		width: 120px;
		height: 120px;
		display: block;
		max-width: 120px;
		border-radius: 100%;
	}

	.change-profile-dialog-footer {
		border-top: 1px solid $border-color;
		justify-content: flex-end;
		padding-top: 5px;
		display: flex;
		gap: 5px;

		.app-bt {
			margin: 0;
		}
	}

	.selected-image-confirm {
		display: flex;
		justify-content: center;
		padding-top: 25px;
		padding-bottom: 25px;

		img {
			display: block;
			width: 150px;
			height: 150px;
			max-width: 150px;
			border-radius: 100%;

		}
	}

	.app-profile-cover {
		background: $background3;
		min-height: 250px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: rgba(255,255,255,0.6);
		border-radius:10px;
		h1 {

			color: $light;
			text-align: center;
		}
	}

	.app-profile-pic-wrap {
		display: flex;
		align-items: center;
		justify-content: start;
		background: transparent;
	}

	.files-list {
		display: flex;
		justify-content: space-between;

		img {
			margin: 5px;
			box-shadow: 0 3px 18px #0000000d;

			&:hover {
				border: 2px solid $light;
			}
		}

		.file-list-core {}

		.file-list-right-col {
			background: $light;
			border-left: 1px solid $border-color;
			padding: 10px;
			display: flex;
			flex-direction: column;
		}
	}

	.app-profile-photo {
		position: relative;
		padding: 10px;
		margin-top: -56px;
		z-index: 1;

		img {
			border: 5px solid $light;
			box-shadow: 0 8px 16px #0000000d;
		}

		.change-profile-pic-bt {
			position: absolute;
			top: 28px;
			right: 5px;
			background-color: $dark;
			width: 25px;
			height: 25px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid $app-logo-color;
			border-radius: 100%;
			cursor: pointer;

			svg {
				fill: $light;
			}
		}

		.no-profile-wrap {
			display: flex;
			align-items: center;
			justify-content: center;
			background: $light;
			border-radius: 100%;
			width: 100px;
			height: 100px;
			padding: 10px;

			border: 5px solid $light;
			box-shadow: 0 8px 16px #0000000d;

			svg {
				width: 50px;
				height: 50px;
				fill: $background3;
			}
		}
	}

	.view-profile-wrap {
		.app-profile-photo-info {
			h2 {
				margin-top: 45px;
				margin-bottom: 10px;
			}

			p {
				color: $grayMenu;
			}
		}

	}

	.welcome-profile {
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.update-profile-core {
		padding: 10px;
	}

	.app-profile-photo-info {
		margin-top: -50px;

		h2 {
			padding-top: 25px;
			color: $background3;
			font-size: 1.4em;
		}

		.app-bt {
			margin-left: 5px;
		}
	}

	.profile-menu {
		// background: transparent;
		width: 100%;
		
		a {
			color: $grayMenu;
		}

		ul {
			width: 100%;
			@media(max-width: $mobile){
				// display: grid;
				// grid-template-columns: 45% 45%;
			}
		}
	}
}

#profile-top-menu {
	position: fixed;
	top: 0;
	right: 0;
	//z-index: 10;
	// animation: 1s showTopRightMenu; 
	display: flex;
	// flex-direction: column;
	justify-content: flex-end;
	box-shadow: 0 -3px 18px #0000000d;
	overflow-y: scroll;
	padding-top: 0;

	.modal-box {
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0;
		height: 100%;
		margin-top: 0;
		max-width: 400px;
		min-height: 400px;

		@media(max-width: $large) {
			max-width: 80%;
		}
	}

	ul {
		display: flex;
		flex-direction: column;
	}

	li {
		list-style-type: none;
		margin-top: 10px;
	}

	.close-app-bt {
		position: absolute;
		top: 5px;
		right: 5px;
		cursor: pointer;

		svg {
			width: 25px;
			height: 25px;
		}
	}

	.user-photo {
		justify-content: center;
		text-align: center;
	}

	svg {
		fill: $app-logo-color;
	}

}

.top-right-menu {
	position: relative;



	svg {
		fill: $background3;
	}

	.user-name-bt {
		cursor: pointer;
		padding-left: 10px;
		padding-right: 10px;
		color: $background3;
	}
}

.note-editor {
	border: 1px solid $border-color;
	min-height: 250px;
	padding: 5px;
	margin-bottom: 25px;
	max-height: 250px; 
	overflow-y: scroll;
	&:hover, &:focus{
		border-color: $background3;
	}
}

.app-profile-wrap {
	padding-bottom: 50px;

	form {
		label {
			display: block;
		}

		input,
		select,
		textarea {
			display: block;
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			border: 1px solid #ddd;
			margin-bottom: 10px;
			box-shadow: 0 3px 18px #0000000d;
		}
	}

	.user-notes {
		h2 {
			padding-top: 20px;
			padding-bottom: 20px;
		}

		@media (max-width: $tablet) {
			padding: 10px;
		}

		article {
			padding: 20px;
			border-radius: 10px;
			position: relative;
			box-shadow: 0 3px 18px #0000000d;
			margin-bottom: 15px;
		}

		article h3[contentEditable=true] {
			display: block;
			border: 1px solid $border-color;
			width: 100%;
			padding: 5px;
			font-weight: normal;
			margin-bottom: 5px;
			box-shadow: 0 3px 18px #0000000d;
			border-radius: 5px;
		}

		article div[contentEditable=true] {
			display: block;
			border: 1px solid $border-color;
			width: 100%;
			padding: 5px;
			font-weight: normal;
			height: 200px;
			max-height: 200px;
			min-height: 200px;
			overflow-y: scroll;

			box-shadow: 0 3px 18px #0000000d;
			border-radius: 5px;
		}

		.update-note-footer {
			// border-top: 1px solid $border-color;
			margin-top: 10px;
			display: flex;
			justify-content: flex-end;
			gap: 5px;
			padding-top: 10px;

			button {
				margin: 0;
			}
		}

		.allow-update-note {
			position: absolute;
			top: -5px;
			right: 0;
			background: $app-logo-color;
			color: $light;
			padding: 2px;
			border-radius: 5px;
		}

		.error-update-note {
			position: absolute;
			top: -5px;
			right: 0;
			background: $dark;
			color: $light;
			padding: 2px;
			border-radius: 5px;
		}

		.user-notes-title {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.transparent-bt {
				margin: 0;
				color: $app-logo-color;
				position: absolute;
				right: 0;
			}
		}
	}
}

.app-profile-core {
	display: flex;
	justify-content: center;
}

.app-user-stats {
	display: flex;
	justify-content: center;
	gap: 25px;
	padding: 10px;

	@media (max-width: $tablet) {
		flex-direction: column;
		padding-top: 20px;
		padding-bottom: 20px;
	}
}

.uploadLevelContainer {
	height: 5px;
	border-radius: 2px;
	background: #eee;
	position: relative;
	display: block;

	.uploadLevel {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		background: $app-logo-color;
	}
}

@keyframes showTopRightMenu {
	0% {
		width: 10%;
	}

	100% {
		width: 30%;
	}
}

.add-note-footer {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
}

.profile-update-box {
	max-width: 250px;
	padding: 25px;
	font-size: 90%;
	background: $light;
	color: $grayMenu;
	border-radius: 10px; 
	box-shadow: 0 3px 18px #0000000d;
	h2{
		color: $background3;
	}
	svg{
		fill: $background3;
	}
}

.update-flex {
	max-width: 250px;
	flex-direction: column;
	gap: 25px;
}