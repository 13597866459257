.upload-dialog-modal{
	background: rgba(0,0,0,0.4);
	position: fixed;
	top: 0;
	left: 0; 
	width: 100%;
	height: 100%;
	z-index:2000; 
	.upload-dialog-box{
		background: $light; 
		width: 70%;
		max-width: 800px; 
		min-height: 300px; 
		height: 80%;
		display: block;
		// overflow-y: scroll; 
		margin: 0 auto; 
		padding: 25px; 
		margin-top: 5rem;
		position: relative;
	}
	.file-album{
		height: 200px; 
		overflow: hidden;
		overflow-y: scroll; 
		align-items:flex-start;
	}
}
.upload-modal-footer{
	display: flex; 
	justify-content: flex-end; 
	align-items: flex-end;
	gap: 5px;
	padding: 5px; 
	position: absolute;
	bottom: 0; 
	left: 0; 
	width: 100%; 
	height: 50px; 
	background: $gray-bg;
	.app-bt{
		border-radius: 0; 
		padding: 5px; 
		min-width: 80px; 


	}
}

.album-article-selected{
	border: 2px solid $dark;
}