.app-bt {
	background: transparent;
	min-width: 100px;
	min-height: 35px;
	text-align: center;
	border: 1px solid $primary-color !important;
	padding-left: 25px;
	padding-right: 25px;
	color: $primary-color !important;
	cursor: pointer;
	border-radius: 20px !important;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	max-width: 250px;
	margin: auto;
	text-align: center;
	transition: all 0.3s;
	justify-content: center;
	margin-bottom: 10px;

	&:hover {
		background: $primary-color !important;
		color: $light !important;
	}

	svg {
		fill: $app-logo-color;
	}
}

.transparent-bt {
	@extend .app-bt;
	border: none;
	color: $grayMenu;
}

.got-a-project-bt {
	background: $light;
	min-width: 250px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: $app-logo-color;
	font-size: 16px;
	font-weight: bold;

	&:hover {
		color: $app-logo-color;
		font-weight: bold;
		background: $light;
	}
}

.add-note-bt {
	svg {
		fill: $app-logo-color;
	}
}

.cute-upload-bt {
	//border: 1px solid $app-logo-color;
	padding: 10px;
	cursor: pointer;
	color: $app-logo-color;

	svg {
		fill: $app-logo-color;
	}
}

.got-a-project-bt-top {
	min-width: 100px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: transparent;
	//padding-top: 10px;
	//padding-bottom: 10px;
	border-radius: 5px;
	color: $dark;
	border: 1px solid transparent;
	padding-left: 10px;
	padding-right: 10px;
	//margin-top: 2px;
	height: 50px;
	margin-left: 5px;

	&:hover {
		// color: $light;
		font-weight: bold;
		// opacity: 0.9;
		text-decoration: underline;
	}

	.addIcon {
		background: transparent;
	}

	@media (max-width: $large) {
		display: none;
	}
}

.submit-search-bt {
	display: flex;
	align-items: center;
	background: transparent;

	svg {
		width: 18px;
		height: 25px;
	}
}

.load-more-blog-bt {

	// border: 1px solid #000;
	// padding-left: 25px;
	// padding-right: 25px;
	// padding-top: 5px;
	// padding-bottom: 5px;
	// border-radius: 5px;
	// text-align: center;
	// cursor: pointer;
	// margin-top:5px;
	// background: #000;
	// color: #fff;
	@extend .app-bt;
	margin-top: 20px;
}

.read-bt {

	margin-top: 25px;
	display: block;

}

.homepage-bt {
	@extend .app-bt;
	margin: 0;
	background: $background3;
	border-color: $background3;
	color: $light;
	border-radius: 8px;
	text-transform: uppercase;
	text-decoration: none;

	svg {
		margin-left: 10px;
		fill: $light;
	}

	&:hover {
		background: $dark;
		border-color: $dark;
	}
}

.rounded-bt {
	border-radius: 8px;
	border: 1px solid $violet;
	padding: 10px 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: $violet;
	transition: all 0.3s;
}