.each-slide-effect > div{
	display: flex;
	align-items: flex-start;
	justify-content: center;
	background-size: cover;
	height: 350px;
	flex-direction: column;
	background-color: rgba(0,0,0,0.6);
	padding: 50px;

	h2{
		text-transform: uppercase;
	}
	.app-bt{
		
	    border-radius: 8px;
		border-color: $background3;
		background: $background3;
		color: $light;
		text-transform: uppercase;
		font-weight: 600;
	}
}
%slider-icon{
	font-size: 32px; 
	color: $light; 
	width: 44px;
	height: 44px;
	border-radius:100%;
	border: 2px solid $background3;
}
.left-arrow-icon{
	    @extend %slider-icon;
		&:hover{
			animation: animateSliderIcon 1s;
		}
		svg{
			fill: $light;
		}
	}
	.right-arrow-icon{
		@extend %slider-icon;
		&:hover{
			
			animation: animateSliderIcon 1s;
		}
		svg{
			fill: $light;
		}
	}
@keyframes animateSliderIcon{
	0%{
		margin-top: -5px;
	}
	100%{
		margin-top: 0;
	}
	
}
.each-slide-effect .slide-wrap{
	position: relative;
	min-height: 650px;
	.slide-overlay{
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		height: 100%;
		min-height: 100%;
		width: 100%;
		background: rgba(0,0,0,0.4);
	}
	.slide-box{
		
		z-index: 3;
	}
	
}


