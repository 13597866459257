.file-album{
	// display: flex;
	// flex-wrap: wrap; 
	align-items: flex-start;
	display: grid;
	grid-template-columns: 25% 25% 25% 25%;
	article{
		width: 100%;
		min-width: 125px;
		img{
			max-width: 100%; 
			transition: all 0.3s;
			cursor: pointer;
			min-width: 100%;
			height: 150px;
			max-height: 150px;
			&:hover{
				scale: 1.1;
			}
		}
	}
}