.new-article-form{
	display: flex; 
	align-items: flex-start; 
	flex-wrap: wrap;
	width: 100%;
	max-width: 800px; 
	min-width: 250px;
	justify-content: space-between;
} 
.update-article-form-wrap{
	overflow-y: scroll;
}
//overrides mui
.MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root{

}
.css-16ovlnk{
	width: 60%!important;
}
.left-col-new-article{
    min-width: 200px;
    width: 20%;
}
.right-col-new-article{
	min-width: 250px; 
	width: 65%;
	padding: 0 10px 0 10px;
}
.preview-image-wrap{
	background: #808080;
	color: #fff;
	min-height: 200px; 
	height: 50px;
	width: 100%;
	position: relative;
	.preview-image-bt{
		position: absolute; 
		bottom: 0; 
		left: 0; 
		height: 100%;
		background: rgba(0,0,0,0.4);
		width: 100%; 
		padding: 5px; 
		cursor: pointer;
	}
}