
.app-footer{
	background: $light; 
	color: $background3;
	min-height: 200px; 
	padding: 25px;
	padding-top: 25px;
	// border-top-left-radius: 100%;
	// border-top-right-radius: 100%;
	h2{
		min-height: 50px; 
	}
	a.go-top{
	background: $light;
	color: $background3;
	border-radius: 100%;
	border: 2px solid $background2;
	width: 44px;
	height: 44px;
	position: fixed;
	bottom: 10px;
	right: 10px;
	z-index: 5;
	display: flex;
	align-items: center;
	justify-content: center;
	svg{
		fill: $background3;
	}
}
	a,p{
		color: $background3;
		&:hover{
			//color: $light;
		}
	}
	a.homepage-bt{
		color: $light;
		margin-top: 15px;
		
		@media(max-width: $large)
		{
			margin: 0 auto;
			margin-top: 15px;
		}
	}
	li{
		list-style-type: none;
	}

	a.contact-us-bt{
		// background: $app-logo-color;
		color: $light;
		padding-left: 10px;
		padding-right: 10px; 
		padding-top: 5px;
		padding-bottom: 5px;
		min-height: 35px;
		display: flex;
		align-items: center;
		max-width: 200px;
		min-width: 100px;
		border-radius: 5px;
		margin: auto;
		margin-top: 25px;
		justify-content: space-between;
		border: 1px solid $light;
		&:hover{
			padding-top: 6px;
			// background-color: $green-terminal;
		}
		 
	}
 
 	.app-footer-container{
 		justify-content: space-between;
		width: 80%;
		padding: 25px 25px; 
		margin: 0 auto; 
		max-width: 2000px;
		.about-wrap{
			@media (max-width: $large){
				display: flex; 
				flex-direction: column;
				align-items: center;
			}
		}
 		@media (max-width: $large){
 			flex-direction: column;
			justify-content: center;
			row-gap: 50px;
			text-align: center;
 		}
 	}
	.copyright-wrap{
		text-align: center;
		// border-top: 2px solid $light;
		margin-top: 50px;
		min-height: 50px;
		display: flex;
		justify-content: space-between;
		padding-left: 10%;
		padding-right: 10%;
		border-top: 2px solid #e5e5e5;
		padding-top: 10px;
		@media (max-width: $mobile)
		{
			padding-left: 25px;
			padding-right: 25px;
		}
		.footer-logo-wrap{
			align-items: center;
			gap: 10px;
			font-weight: bold;
		}
	}
	.social-wrap{
		min-width: 150px;
		@media (max-width: $large)
		{
			max-width: 250px;
		    margin: auto;
		}
		.app-flex{
			justify-content: space-between;
		}
		svg{
			width: 32px;
			height: 32px;
			fill: $background3;
		}
	}
}

.app-footer-service{
	display: flex;
	// background: $app-logo-color;
	color: $light;
	min-height: 100px;
	justify-content: space-between;
	padding: 50px;
	 
	
	@media (max-width: $tablet)
	{
		padding-left: 25px;
		padding-right: 25px;
	}
 
    .section-default-wrap{
		display: flex;
		justify-content: space-between; 
		width: 100%;
		padding-left: 25px; 
		padding-right: 25px;
	}
}
